import React, { useState } from 'react';
import {
  Button,
  CommonText,
  Divider,
  FormGroup,
  GroupOptionItems,
  Input,
  Sidebar,
  FooterSection,
  SidebarBodySection,
  SidebarBodySectionTop,
} from '@lib/components';
import styled from 'styled-components';
import { MobXProviderContext } from 'mobx-react';
import { deselectAllOptions, updateOptionsUtil } from '../../../../../utils/pause_services';
import { RadioCircleCheck } from '../../../../common/icon/RadioCircleCheck';
import {
  durationOptionsConstant,
  reasonOptionsConstant,
} from './constants';

type PauseServicesSidebarProps = {
  isOpen: boolean;
  close: () => void;
  panel: string;
  currentMenuItemId?: string;
  currentMenuItem: T.Schema.Restaurant.Menu.RestaurantDish | null;
  currentMenuItems: T.Schema.Restaurant.Menu.RestaurantDish[];
  nextDayOpenTime?: string;
};

const ButtonTextWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ disabled }) => (disabled ? '#6B7270' : '#F1F4F7')};
`;

export const PauseSidebar: React.FC<PauseServicesSidebarProps> = ({ isOpen, close, panel, currentMenuItemId = "", currentMenuItem, currentMenuItems, nextDayOpenTime = "09:00" }) => {
  const [durationOptions, setDurationOptions] = useState<TOptionItem[]>(durationOptionsConstant);
  const [reasonOptions, setReasonOptions] = useState<TOptionItem[]>(reasonOptionsConstant);
  const [durationInputVal, setDurationInputVal] = useState('');
  const [reasonInputVal, setReasonInputVal] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { store } = React.useContext(MobXProviderContext);
  const restaurantId = store.restaurant._id;

  const updateDurationOptions = (id: string) => {
    updateOptionsUtil(id, [...durationOptionsConstant], setDurationOptions, false);
    setDurationInputVal('');
  };

  const updateReasonOptions = (id: string) => {
    updateOptionsUtil(id, reasonOptions, setReasonOptions, false);
    setReasonInputVal('');
  };

  const resetFields = () => {
    deselectAllOptions([...durationOptionsConstant], setDurationOptions);
    deselectAllOptions(reasonOptionsConstant, setReasonOptions);
    setDurationInputVal('');
    setReasonInputVal('');
  };

  const selectedDuration = durationOptions.find(item => item.isActive);
  const duration = selectedDuration ? +selectedDuration.value * 1000 * 60 : +durationInputVal * 1000 * 60;
  const selectedReason = reasonOptions.find(item => item.isActive);
  const reason = selectedReason ? selectedReason.value : reasonInputVal;

  const disabledConfirmBtn = !duration || !reason;

  const handleConfirm = async () => {
    if (!disabledConfirmBtn && currentMenuItems && currentMenuItems.length > 0) {
      setIsSubmitting(true)
      const { pausedMenuItems } = store;

      const timeDiffToClose = store.getTimeDiffToCloseStoreInMiliseconds(store.restaurant);
      let pauseDuration = duration;

      if (duration > timeDiffToClose) {
        pauseDuration = timeDiffToClose;
      }

      const pausedIds = pausedMenuItems.map((item: any) => item._id);
      const mappedCurrentMenuItems = currentMenuItems.map(currentItem => ({ ...currentItem, duration })).filter(item => !pausedIds.includes(item._id));
      const dishIds = mappedCurrentMenuItems.map(item => item._id);
      await store.api.menu_dish_update_operations({
        _id: restaurantId,
        dish_ids: dishIds || [],
        status: 'paused',
        duration: pauseDuration,
        reason,
      });
      await store.service.restaurant.get();

      setTimeout(async() => {
        const dishesUpdate = store.restaurant.menus.map((menu: { categories: any[]; }) => {
          return menu.categories.map(category => {
            return category.dishes.filter((dish: T.Schema.Restaurant.Menu.RestaurantDish) => dishIds.includes(dish._id))
          })
        }).flat(2).map((dish: T.Schema.Restaurant.Menu.RestaurantDish) => {
          return {
            dish_id: dish._id,
            pause_end: dish.pause_end,
          }
        })
        if (dishesUpdate.every((dish: any) => dish.pause_end !== null)) {
          await store.api.menu_dish_update_operations({
            _id: restaurantId,
            dish_ids: dishIds || [],
            status: 'resume',
            duration: 0,
            reason: '',
          });
          await store.service.restaurant.get();
        }
      }, pauseDuration);
      resetFields();
      setIsSubmitting(false)
      close();
    }
  };
  const onDurationChange = (e: any) => {
    if (e.target.value < 0) {
      setDurationInputVal('');
      return;
    }
    setDurationInputVal(e.target.value);
    if (e.target.value.length === 1) {
      deselectAllOptions([...durationOptionsConstant], setDurationOptions);
    }
  };

  const onReasonChange = (e: any) => {
    setReasonInputVal(e.target.value);
    if (e.target.value.length === 1) {
      deselectAllOptions(reasonOptionsConstant, setReasonOptions);
    }
  };

  return (
    <Sidebar isOpen={isOpen} headerTitle="Pause Item" width={420} onClose={() => {
      resetFields()
      close()
    }}>
      <SidebarBodySection>
        <SidebarBodySectionTop>
          <CommonText size="small" weight={500} color="#0C202A">
            Pausing Item will make the Item unavailable.
          </CommonText>
          <CommonText size="semiMedium" weight={600} color="#4B4F5E">
            Duration:
          </CommonText>
          <GroupOptionItems optionItems={durationOptions} updateOptions={updateDurationOptions} />
          <FormGroup className="m-b-0" small_title={true} no_border={true}>
            <Input
              placeholder={`Others (Type in minues)`}
              required={true}
              type="number"
              step={0.01}
              style={{
                marginBottom: '16px',
                fontFamily: 'Inter',
              }}
              value={durationInputVal}
              onChange={onDurationChange}
              className="no-round"
            />
          </FormGroup>
          <Divider />
          <CommonText size="semiMedium" weight={600} color="#4B4F5E">
            Reason:
          </CommonText>
          <GroupOptionItems optionItems={reasonOptionsConstant} updateOptions={updateReasonOptions} />
          <FormGroup className="m-b-0" small_title={true} no_border={true}>
            <Input
              placeholder={`Others`}
              step={0.01}
              style={{
                marginBottom: '16px',
                fontFamily: 'Inter',
              }}
              value={reasonInputVal}
              onChange={onReasonChange}
              className="no-round"
            />
          </FormGroup>
        </SidebarBodySectionTop>
      </SidebarBodySection>
      <FooterSection width={420}>
          <Button
            full={true}
            type="button"
            overrideColor={disabledConfirmBtn || isSubmitting ? '#DFDFDF' : '#FB2F2F'}
            onClick={handleConfirm}
          >
            <ButtonTextWrapper disabled={disabledConfirmBtn || isSubmitting}>
              <RadioCircleCheck color={disabledConfirmBtn || isSubmitting ? '#6B7270' : '#F1F4F7'} />
              Confirm
            </ButtonTextWrapper>
          </Button>
        </FooterSection>
    </Sidebar>
  );
};
